import React, {useContext, useState} from "react";
import LocalizedStrings, {LocalizedStringsMethods} from "react-localization";
import {Language} from "../reducer/application/types";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../types/types";
import {useRemoteLocalization} from "../hooks/useRemoteLocalization";
import {useActiveLanguages} from "../hooks/useActiveLanguages";
import {setLanguage} from "../reducer/application/application";
import Cookies from 'js-cookie';


export const FALLBACK_LANGUAGE = 'en';

interface Input {
    label: string;
    placeholder: string;
}

interface SlideshowSlide {
    title: string;
    button: string;
    mobile: string;
}

export interface IMessage extends LocalizedStringsMethods {
    isLoadingLanguage: boolean;
    royalLounge: string;
    appName: string;
    language: string;
    header: {
        title: string;
    };
    home: {
        description: {
            meetTheRoyals: {
                headline: string;
                content: string;
            };
            infoText: {
                card1: {
                    overline: string;
                    headline: string;
                    content: string;
                    button: string;
                };
                card2: {
                    overline: string;
                    headline: string;
                    content: string;
                    button: string;
                };
                card3: {
                    overline: string;
                    headline: string;
                    content: string;
                    button: string;
                };
                card4: {
                    overline: string;
                    headline: string;
                    content: string;
                    button: string;
                };
            };
            colorYourDay: {
                headline: string;
                content: string;
            };
            findYourStyle: {
                headline: string;
                content: string;
            };
            toggle: {
                desktop: {
                    open: string;
                    close: string;
                };
                mobile: {
                    open: string;
                    close: string;
                };
            };
        };
        slider: {
            slide1: SlideshowSlide;
            slide2: SlideshowSlide;
            slide3: SlideshowSlide;
            slide4: SlideshowSlide;
            slide5: SlideshowSlide;
            slide6: SlideshowSlide;
            slide7: SlideshowSlide;
            slide8: SlideshowSlide;
        };
        social: {
            instagram: {
                caption: string;
                captionMobile: string;
                tag: string;
            };
        };
    };
    royalKingdom: {
        title: string;
        subline: string;
        input: Input;
        phone: string;
        mail: string;
        help: string;
        search: string;
        empty: string;
    };
    menu: {
        title: string;
        collection: string;
        fitting: string;
        brandWorld: string;
        story: string;
        profile: string;
        logout: string;
        login: string;
        register: string;
        contact: {
            title: string;
            mailQuestions: string;
            mail: string;
            telQuestions: string;
            tel: string;
            openingHours: string;
        };
        personalFit: {
            title: string;
        }
        storefinder: string;
        updatePassword: string;
        findYourStyle: string;
        colorYourDay: string;
        showAllProducts: string;
        showLessProducts: string;
        storeFinder: string;
        blog: string;
        passform: string;
        orders: string;
        cart: string;
        checkout: string;
        profileShort: string;
        admin: string;
        language: {
            select: string;
        };
        tooltips: {
            language: {
                title: string;
                german: string;
                english: string;
            };
            royalKingdom: string;
            password: string;
            stock: string;
            cart: string;
            profile: string;
        };
        adminItems: {
            orders: string;
            products: string;
            colors: string;
            styles: string;
            coupons: string;
            changelog: string;
            newsBar: string;
            blog: string;
            translation: string;
            activeHomePage: string;
        };
        subNav: {
            colorYourDay: string;
            allBras: string;
            allPanties: string;
            allSets: string;
            allAccessories: string;
        };
    };
    user: {
        orders: {
            title: string;
            empty: string;
            detail: string;
            summary: string;
            dialog: {
                title: string;
                close: string;
            };
            payment: {
                title: string;
                orderNumber: string;
                paymentInAdvance: string;
                creditCard: string;
                paypal: string;
                klarna: string;
                owing: string;
                cancelled: string;
                received: string;
                finished: string;
                shippedDialog: {
                    product: string;
                    name: string;
                    quantity: string;
                    color: string;
                    size: string;
                };
            };
            deliveryAddress: {
                title: string;
                email: string;
                phone: string;
                notSpecified: string;
            },
            billingAddress: {
                title: string;
            };
        };
        login: {
            title: string;
            submit: string;
            register: string;
            error: string;
            email: Input;
            password: Input;
        };
        resetPassword: {
            title: string;
            subLine: string;
            email: Input;
            submit: string;
            success: string;
            error: string;
        };
        updatePassword: {
            title: string;
            password: {
                label: string;
                placeholder: string;
            };
            passwordConfirm: {
                label: string;
                placeholder: string;
            };
            submit: string;
            success: string;
            error: string;
        };
        register: {
            title: string;
            subtitle: string;
            titleShort: string;
            description: string;
            submit: string;
            firstName: Input;
            lastName: Input;
            email: Input;
            password: Input;
            success: string;
            error: string;
        };
    };
    contact: {
        title: string;
        name: string;
        street: string;
        streetNr: string;
        zip: string;
        city: string;
        email: string;
        phone: string;
        hours: string;
        form: {
            title: string;
            subline: string;
            subject: Input;
            name: Input;
            email: Input;
            phone: Input;
            invoiceNumber: Input;
            message: Input;
            copy: string;
            privacy: string;
            submit: string;
            success: string;
            error: string;
            info: {
                address: string;
                email: string;
                phone: string;
                phoneHours: string;
            };
        };
    };
    aboutUs: {
        title: string;
        story: {
            title: string;
        };
        news: {
            title: string;
        };
        brandWorld: {
            title: string;
        };
        sustainability: {
            title: string;
        };
        press: {
            title: string;
        };
    };
    help: {
        title: string;
    };
    meetTheRoyals: {
        title: string;
    };
    colorYourDay: {
        title: string;
        headline: string;
        subline: string;
        metaTag: string;
        seo: {
            caption: string;
            title: string;
            text: {
                part1: string;
                part2: string;
            };
        };
    };
    findYourStyle: {
        title: string;
        subLine: string;
        bestStyle: string;
        otherOptions: string;
        site: {
            title: string;
            subLine: string;
            description: {
                title: string;
                subLine: string;
                text: string;
            };
        };
        detail: {
            subLine: string;
        };
        smallPetite: {
            title: string;
            headline: string;
            subLine: string;
            description: string;
        };
        slimBusty: {
            title: string;
            headline: string;
            subLine: string;
            description: string;
        };
        curvyModest: {
            title: string;
            headline: string;
            subLine: string;
            description: string;
        };
        curvyBusty: {
            title: string;
            headline: string;
            subLine: string;
            description: string;
        };
    };
    measureYourSize: {
        title: string;
        subLine: string;
        input: {
            title: string;
            placeholder: string;
            calculate: string;
            selectUnit: string;
        };
        bras: {
            title: string;
            subLine: string;
            underBust: {
                title: string;
                measurement: string;
                measurementBold: string;
            };
            overBust: {
                title: string;
                measurement: string;
                measurementBold: string;
            };
            overUnderBust: {
                title: string;
                measurement: string;
            };
            calculator: {
                result: string;
                link: string;
            };
            errors: {
                missing: string;
                tooLowCM: string;
                tooLowIN: string;
                notFound: string;
            };
        };
        panties: {
            title: string;
            subLine: string;
            hipSize: {
                title: string;
                measurement: string;
                measurementBold: string;
            };
            calculator: {
                result: string;
                link: string;
            };
            errors: {
                missing: string;
                tooLowCM: string;
                tooLowIN: string;
            };
        };
        description: {
            title: string;
            subLine: string;
            text: string;
        };
    };
    visitFittingRoom: {
        title: string;
        perfectYourFit: {
            title: string;
            subLine: string;
            greatSupport: {
                title: string;
                content: string;
            };
            feelsRight: {
                title: string;
                content: string;
            };
            niceAndStraight: {
                title: string;
                content: string;
            };
            excellentCoverage: {
                title: string;
                content: string;
            };
            staysInPlace: {
                title: string;
                content: string;
            };
            perfectFit: {
                title: string;
                content: string;
            };
        };
        description: {
            title: string;
            subLine: string;
            text: string;
        };
    };
    perfectYourSize: {
        title: string;
        subLine: string;
        sections: {
            styleFinder: string;
            guidingTable: string;
            fittingTips: string;
            measureYourSize: string;
            optimiseFit: string;
        };
        description: {
            title: string;
            subLine: string;
            text: string;
        };
    };
    guidingTable: {
        title: string;
        subLine: string;
        bras: string;
        centimeters: string;
        inches: string;
        bustSize: string;
        chest: string;
        panties: string;
        slipSizes: string;
        hip: string;
        description: {
            title: string;
            subLine: string;
            text: string;
        };
    };
    fitOptimization: {
        title: string;
        subLine: string;
        overline: string;
        step1: {
            title: string;
            continueBtn: string;
        };
        step2: {
            title: string;
            sideShort: string;
            sideLong: string;
            sideTitleMobile: string;
            sideShortMobile: string;
            sideLongMobile: string;
            cupBig: string;
            cupSmall: string;
            cupTitleMobile: string;
            cupBigMobile: string;
            cupSmallMobile: string;
            backBtn: string;
            continueBtn: string;
        };
        step3: {
            title: string;
            yourSize: string;
            seeProductsBtn: string;
            noResultMessage: string;
            startAgainBtn: string;
        };
        info: {
            crossSizes: {
                title: string;
                subline: string;
            };
            cupSizes: {
                title: string;
                subline: string;
            };
            didYouKnow: {
                title: string;
                text: string;
            };
        };
        description: {
            title: string;
            subLine: string;
            text: string;
        };
    };
    products: {
        sizes: {
            select: {
                placeholder: string;
            };
            measureYourSize: string;
            label: string;
            findYourSize: string;
        };
        social: {
            lowStock: string;
            inStock: string;
            inXCarts: string;
        },
        benefits: {
            freeShipping: string;
            fastShipping: string;
            freeReturns: string;
        },
        relatedProducts: string;
        recentlyViewed: string;
        notify: {
            title: string;
            subline: string;
            button: string;
            success: string;
            successTitle: string;
        },
        cart: {
            add: string;
        };
        info: {
            details: string;
            description: string;
            care: string;
            highlights: string;
            manufactureAndProductSafety: string;
            recommendationTitle: string;
            reviews: string;
        };
        filter: {
            style: string;
            color: string;
            size: string;
            price: string;
            mobile: {
                prevStyle: string;
                nextStyle: string;
                filter: string;
                cancel: string;
                submit: string;
                resetAll: string;
                style: string;
                size: {
                    bras: string;
                    panties: string;
                };
                color: string;
                price: string;
            };
        };
        outOfStock: string;
        overview: {
            highlights: {
                productType: {
                    title: string;
                    subTitle: string;
                };
                all: {
                    title: string;
                    subTitle: string;
                };
                mobile: {
                    title: string;
                };
            };
        };
    };
    cart: {
        add: {
            success: string;
            error: {
                size: string;
                color: string;
            };
        };
        recommendationTitle: string;
        shippingFastHeadline: string;
        shippingFastSubline: string;
        headline: string;
        total: string;
        checkout: string;
        checkoutMobile: string;
        continue: string;
        color: string;
        size: string;
        quantity: string;
        empty: {
            hint: string;
            subline: string;
        };
    };
    checkout: {
        title: string;
        success: string;
        steps: {
            personal: string;
            delivery: string;
            payment: string;
            complete: string;
        };
        klarna: {
            noOptions: string;
            rejected: string;
            title: string;
            subline: string;
            continue: string;
            backToPaymentOption: string;
            cancel: string;
        };
        contact: {
            title: string;
            login: {
                question: string;
                login: string;
            };
            welcomeBack: string;
        };
        delivery: {
            title: string;
        };
        personalData: {
            title: string;
            login: string;
            validation: {
                street: string;
                email: string;
            };
            company: Input;
            firstName: Input;
            lastName: Input;
            street: Input;
            amendment: {
                toggle: string;
                toggleOff: string;
            } & Input;
            city: Input;
            zip: Input;
            country: Input;
            email: Input;
            gender: {
                label: string;
                placeholder: string;
                male: string;
                female: string;
                notApplicable: string;
                none: string;
            };
            password: Input;
            passwordConfirm: Input;
            continue: string;
            useAsDeliveryAddress: string;
            createLogin: string;
            newsletter: string;
        };
        deliveryInformation: {
            title: string;
            phone: Input & {
                labelRequired: string;
            };
            phoneInformation: string;
            back: string;
        };
        giftWrap: {
            title: string;
            description: string;
            tooltip: string;
        },
        paymentInformation: {
            title: string;
            paypal: string;
            creditCard: string;
            klarna: string;
            paymentInAdvance: string;
            back: string;
            click: string;
            description: {
                paypal: string;
                klarna: string;
                paymentInAdvance: string;
            };
            mollieSepa: string;
            mollieCreditCard: string;
            molliePaypal: string;
            mollieKlarna: string;
            mollieApplePay: string;
            mollieGooglePay: string;
            information: string;
        };
        billingAddress: {
            select: {
                sameAsDelivery: string;
                custom: string;
            };
        };
        legal: {
            description: string;
            termsAndConditions: string;
            privacy: string;
            rightOfRevocation: string;
            exchangeAndRefund: string;
        };
        finish: {
            back: string;
            success: {
                title: string;
                subline: string;
                continue: string;
            };
            error: {
                general: string;
            };
        };
        coupons: {
            title: string;
            freeShipping: string;
            input: Input;
            button: string;
            success: string;
            legal: {
                title: string;
            };
            error: {
                multiple: string;
                generic: string;
            };
            invalidCheckout: {
                content: string;
                close: string;
            };
        };
        accept: string;
        checkout: string;
        creditCard: {
            title: string;
            submit: string;
        };
        cart: {
            title: string;
            subtotal: string;
            shipping: string;
            total: string;
            vat: string;
            vatPlain: string;
            vatLong: string;
            error: string;
            free: string;
            savings: string;
            toggle: {
                show: string;
                hide: string;
            };
        };
    };
    story: {
        headline: string;
        royalLoveStory: string;
        part1: string;
        part2: string;
        part3: string;
        part4: string;
        part5: string;
        part6: string;
        subline: string;
        mobile: {
            part6: {
                part1: string;
                part2: string;
            };
        };
        about: {
            title: string;
            subLine: string;
            part1: string;
            part2: string;
            part3: string;
            part4: string;
            caption: string;
        };
    };
    brandWorld: {
        title: string;
        caption: string;
        profile: {
            title: string;
            mobileTitle: string;
            caption: string;
        };
        fittingRoom: {
            title: string;
            caption: string;
        };
        washingTips: {
            title: string;
            caption: string;
        };
        content: {
            text: string;
            title: string;
        };
    };
    sustainability: {
        title: string;
        caption: string;
        livingCleanAndGreen: string;
        production: {
            title: string;
            text1: string;
            text2: string;
            text3: string;
        };
        products: {
            title: string;
            text1: string;
            text2: string;
            text3: string;
        };
        care: {
            title: string;
            text1: string;
            text2: string;
        };
        packaging: {
            title: string;
            text1: string;
            text2: string;
        };
        shipping: {
            title: string;
            text1: string;
        };
    };
    cookies: {
        description: string;
        showSettings: string;
        declineAll: string;
        acceptAll: string;
        consent: {
            title: string;
            description: string;
            amendmentTitle: string;
            amendment: string;
            accept: string;
            decline: string;
            on: string;
            off: string;
            essential: {
                title: string;
                titleMobile: string;
                description: string;
            };
            tracking: {
                title: string;
                titleMobile: string;
                description: string;
                vendors: {
                    analytics: string;
                    ads: string;
                    tagManager: string;
                    facebook: string;
                    instagram: string;
                    hotjar: string;
                    microsoft: string;
                };
            };
        };
    };
    faq: {
        title: string;
        footer: string;
        caption: string;
        contact: {
            phone: {
                title: string;
                number: string;
            };
            email: {
                title1: string;
                title2: string;
                email: string;
            };
        };
        company: {
            title: string;
            element1: {
                title: string;
                content: string;
            };
            element2: {
                title: string;
                content: string;
            };
            element3: {
                title: string;
                content: string;
            };
        };
        products: {
            title: string;
            element1: {
                title: string;
                content: string;
            };
            element2: {
                title: string;
                content: string;
            };
            element3: {
                title: string;
                content: string;
            };
        };
        account: {
            title: string;
            element1: {
                title: string;
                content: string;
            };
            element2: {
                title: string;
                content: string;
            };
            element3: {
                title: string;
                content: string;
            };
            element4: {
                title: string;
                content: string;
            };
        };
        payment: {
            title: string;
            element1: {
                title: string;
                content: string;
            };
            element2: {
                title: string;
                content: string;
            };
        };
        shipping: {
            title: string;
            element1: {
                title: string;
                content: string;
            };
            element2: {
                title: string;
                content: string;
            };
            element3: {
                title: string;
                content: string;
            };
            element4: {
                title: string;
                content: string;
            };
            element5: {
                title: string;
                content: string;
            };
        };
        replacement: {
            title: string;
            element1: {
                title: string;
                content: string;
            };
        };
        returns: {
            title: string;
            element1: {
                title: string;
                content: string;
            };
            element2: {
                title: string;
                content: string;
            };
            element3: {
                title: string;
                content: string;
            };
        };
        refund: {
            title: string;
            element1: {
                title: string;
                content: string;
            };
        };
    };
    returns: {
        title: string;
        caption: string;
        replacement: {
            title: string;
            content: string;
        };
        return: {
            title: string;
            mail: string;
            phone: string;
            address: {
                company: string;
                street: string;
                city: string;
                country: string;
            };
            part1: string;
            part2: string;
            part3: string;
            part4: string;
            part5: string;
            part6: string;
        };
        refund: {
            title: string;
            content: string;
        };
    };
    payments: {
        title: string;
        caption: string;
        payment: {
            title: string;
            part1: {
                title: string;
                content: string;
            };
            part2: {
                title: string;
                content: string;
            };
            part3: {
                title: string;
                content: string;
            };
            part4: {
                title: string;
                content: string;
            };
        };
        shipping: {
            title: string;
            part1: string;
            part2: string;
            part3: string;
            part4: string;
            part5: string;
            part6: string;
        };
    };
    washing: {
        title: string;
        caption: string;
        preparation: {
            title: string;
            part1: string;
            part2: string;
        };
        handWash: {
            title: string;
            part1: string;
            part2: string;
            part3: string;
            part4: string;
        };
        washingMachine: {
            title: string;
            part1: string;
        };
        dryer: {
            title: string;
            part1: string;
        };
        keeping: {
            title: string;
            part1: string;
        };
    };
    footer: {
        contact: {
            title: string;
        };
        social: {
            title: string;
        };
        newsletter: {
            title: string;
            button: string;
            subLine: string;
            input: Input;
            success: string;
            error: string;
        };
        shipping: {
            step1: string;
            step2: string;
            step3: string;
            step4: string;
        };
        trustedShops: {
            rating: string;
        };
    };
    newsletter: {
        subscribe: {
            caption: string;
            headline: string;
            title: string;
            text: string;
            backToHome: string;
        };
        unsubscribe: {
            caption: string;
            headline: string;
            title: string;
            text: string;
            backToHome: string;
        };
        dialog: {
            title: string;
            subTitle: string;
            button: string;
            input: {
                placeholder: string;
                label: string;
            };
        };
    };
    legal: {
        termsConditions: {
            title: string;
        };
        legalNotice: {
            title: string;
        };
        disclaimer: {
            title: string;
        };
        privacyPolicy: {
            title: string;
        };
        withdrawal: {
            title: string;
        };
        copyright: string;
    };
    system: {
        typography: {
            title: string;
        };
    };
    blog: {
        menu: {
            all: string;
        };
        overview: {
            header: {
                title: string;
                caption: string;
            };
            categories: {
                posts: {
                    showAll: string;
                };
            };
        };
        posts: {
            backToCategory: string;
            related: string;
            actions: {
                text: string;
            };
        };
    };
}


const localizedStrings = new LocalizedStrings({
    de: {} as IMessage,
    en: {} as IMessage,
    it: {} as IMessage,
    es: {} as IMessage,
    nl: {} as IMessage
});

const Localize = React.createContext(localizedStrings);

export function useLocalization() {
    const localized = useContext(Localize);
    const language: Language = useSelector<AppState, Language>(
        (state) => state.application.language
    );
    localized.setLanguage(language);

    useEffect(() => {
        localized.setLanguage(language);
    }, [localized, language]);

    return localized;
}

function LocalizationProvider({children}: { children: any }) {
    const dispatch = useDispatch();
    const language: Language = useSelector<AppState, Language>(
        (state) => state.application.language
    );
    const [languageChecked, setLanguageChecked] = useState(false);


    // Check if selected language is active
    const admin = useSelector((state: AppState) => state.user.admin);

    const {
        data: supportedLangs,
        isLoading: isLoadingLangs,
        isSuccess: isSuccessLangs
    } = useActiveLanguages(admin, {enabled: true});

    useEffect(() => {
        if (isSuccessLangs && !isLoadingLangs) {
            const langs = supportedLangs.map((lang) => lang.language);
            if (!langs.includes(language)) {
                // Fallback to selected language
                dispatch(setLanguage(Language.GERMAN));
                Cookies.set('language', Language.GERMAN);
            }
            setLanguageChecked(true);
        }
    }, [isSuccessLangs, isLoadingLangs, supportedLangs, language, dispatch]);

    const {data, isLoading, isSuccess} = useRemoteLocalization(language, {
        enabled: true,
    });

    return (
        <Localize.Provider
            value={
                new LocalizedStrings(
                    isSuccess && !isLoading && languageChecked
                        ? {[language]: {...data} as unknown as IMessage}
                        : {[language]: {isLoadingLanguage: true} as unknown as IMessage}
                )
            }
        >
            {children}
        </Localize.Provider>
    );
}

export default LocalizationProvider;
