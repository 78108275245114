import React, {
    CSSProperties,
    MouseEventHandler,
    RefCallback,
    RefObject,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import {ImageSet, ImageSize} from '../../types/types';
import {ImageFormat} from '../../types/products/types';
import {Theme, useMediaQuery, useTheme} from '@mui/material';

interface ImageProps {
    imageSet: ImageSet;
    pictureClass?: string;
    alt: string
    imageClass: string;
    imageStyle?: CSSProperties;
    title: string;
    onClick?: MouseEventHandler;
    imageRef?: RefObject<HTMLImageElement> | RefCallback<HTMLImageElement>;
    onLoad?: () => void;
    play?: boolean;
    showPoster?: boolean;
    preventDrag?: boolean;
    useOnClickOnVideo?: boolean;
    useVideoPoster?: boolean;
}

export const isVideo = (imageSet: ImageSet) => {
    return Boolean(imageSet.get(ImageFormat.VIDEO_MP4)?.size) ||
        Boolean(imageSet.get(ImageFormat.VIDEO_WEBM)?.size) || Boolean(imageSet.get(ImageFormat.VIDEO_QUICKTIME)?.size)
}

const Image: React.FC<ImageProps> = ({
                                         imageSet,
                                         pictureClass,
                                         alt,
                                         imageClass,
                                         imageStyle,
                                         title,
                                         onClick,
                                         imageRef,
                                         onLoad,
                                         play = true,
                                         showPoster = true,
                                         preventDrag = true,
                                         useOnClickOnVideo = false,
                                         useVideoPoster = false
                                     }) => {

    const [showVideoPoster, setShowVideoPoster] = useState(showPoster);
    const theme = useTheme<Theme>();
    const videoRef = useRef<HTMLVideoElement>(null);
    const isWidthDownXS = useMediaQuery(theme.breakpoints.down('sm'));
    const isWidthDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const isWidthDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    const currentBreakpoint: ImageSize = useMemo(() => {
        if (isWidthDownXS) {
            return 'xs';
        }
        if (isWidthDownSM) {
            return 'sm';
        }
        if (isWidthDownMD) {
            return 'md';
        }
        return 'lg';
    }, [isWidthDownMD, isWidthDownXS, isWidthDownSM]);

    useEffect(() => {
        if (videoRef.current) {
            if (play) {
                setShowVideoPoster(false);
                videoRef.current.play();
            } else {
                setShowVideoPoster(true);
                videoRef.current.pause();
                videoRef.current.currentTime = 0;
                videoRef.current.load();
            }
        }
    }, [play]);

    if (Boolean(imageSet.get(ImageFormat.SVG)?.size)) {
        const map = imageSet.get(ImageFormat.SVG);
        if (map) {
            return <img src={map.get(map.keys().next().value)} className={imageClass} alt={alt} title={title}
                        onClick={onClick} style={imageStyle}/>;
        }
        return null;
    }

    let video;
    if (isVideo(imageSet) && !useVideoPoster) {
        video = (
            <video controls={false} autoPlay={play} muted={true} className={imageClass} loop={true} playsInline={true}
                   onClick={useOnClickOnVideo ? onClick : undefined}
                   ref={videoRef}
                   onLoadedMetadata={() => {
                       setShowVideoPoster(!play);
                   }} style={{
                ...(imageStyle || {}),
                opacity: showVideoPoster ? 0 : 1,
                transition: theme.defaultTransition('all')
            }}>
                {Boolean(imageSet.get(ImageFormat.VIDEO_MP4)) && <source type={ImageFormat.VIDEO_MP4}
                                                                         src={imageSet.get(ImageFormat.VIDEO_MP4)?.get(currentBreakpoint)}/>}
                {Boolean(imageSet.get(ImageFormat.VIDEO_WEBM)) && <source type={ImageFormat.VIDEO_WEBM}
                                                                          src={imageSet.get(ImageFormat.VIDEO_WEBM)?.get(currentBreakpoint)}/>}
                {Boolean(imageSet.get(ImageFormat.VIDEO_QUICKTIME)) && <source type={ImageFormat.VIDEO_QUICKTIME}
                                                                               src={imageSet.get(ImageFormat.VIDEO_QUICKTIME)?.get(currentBreakpoint)}/>}
            </video>);
    }

    return (
        <>
            <picture className={pictureClass} style={{
                opacity: showVideoPoster ? 1 : 0,
                transition: theme.defaultTransition('all'),
                lineHeight: 1
            }}>
                {Boolean(imageSet.get(ImageFormat.WEBP)?.get('lg2x')) &&
                    <source
                        srcSet={`${imageSet.get(ImageFormat.WEBP)?.get('lg2x')} 2x, ${imageSet.get(ImageFormat.WEBP)?.get('lg')} 1x`}
                        type={'image/webp'}
                        media={'(min-width: 1200px)'}/>}
                {Boolean(imageSet.get(ImageFormat.WEBP)?.get('md2x')) &&
                    <source
                        srcSet={`${imageSet.get(ImageFormat.WEBP)?.get('md2x')} 2x, ${imageSet.get(ImageFormat.WEBP)?.get('md')} 1x`}
                        type={'image/webp'}
                        media={'(min-width: 901px)'}/>}
                {Boolean(imageSet.get(ImageFormat.WEBP)?.get('sm2x')) &&
                    <source
                        srcSet={`${imageSet.get(ImageFormat.WEBP)?.get('sm2x')} 2x, ${imageSet.get(ImageFormat.WEBP)?.get('sm')} 1x`}
                        type={'image/webp'}
                        media={'(min-width: 601px)'}/>}
                {Boolean(imageSet.get(ImageFormat.WEBP)?.get('xs2x')) &&
                    <source
                        srcSet={`${imageSet.get(ImageFormat.WEBP)?.get('xs2x')} 2x, ${imageSet.get(ImageFormat.WEBP)?.get('xs')} 1x`}
                        type={'image/webp'}
                        media={'(max-width: 600px)'}/>}
                {Boolean(imageSet.get(ImageFormat.FALLBACK)?.get('lg2x')) &&
                    <source
                        srcSet={`${imageSet.get(ImageFormat.FALLBACK)?.get('lg2x')} 2x, ${imageSet.get(ImageFormat.FALLBACK)?.get('lg')} 1x`}
                        media={'(min-width: 1200px)'}/>}
                {Boolean(imageSet.get(ImageFormat.FALLBACK)?.get('md2x')) &&
                    <source
                        srcSet={`${imageSet.get(ImageFormat.FALLBACK)?.get('md2x')} 2x, ${imageSet.get(ImageFormat.FALLBACK)?.get('md')} 1x`}
                        media={'(min-width: 901px)'}/>}
                {Boolean(imageSet.get(ImageFormat.FALLBACK)?.get('sm2x')) &&
                    <source
                        srcSet={`${imageSet.get(ImageFormat.FALLBACK)?.get('sm2x')} 2x, ${imageSet.get(ImageFormat.FALLBACK)?.get('sm')} 1x`}
                        media={'(min-width: 601px)'}/>}
                {Boolean(imageSet.get(ImageFormat.FALLBACK)?.get('sm2x')) &&
                    <source
                        srcSet={`${imageSet.get(ImageFormat.FALLBACK)?.get('xs2x')} 2x, ${imageSet.get(ImageFormat.FALLBACK)?.get('xs')} 1x`}
                        type={'image/webp'}
                        media={'(max-width: 600px)'}/>}
                <img className={imageClass} src={imageSet.get(ImageFormat.FALLBACK)?.get('lg')} onMouseOver={() => {
                    if (Boolean(videoRef.current)) {
                        videoRef.current?.play();
                        setShowVideoPoster(false);
                    }
                }} onDragStart={preventDrag ? (e) => {
                    e.preventDefault();
                    return false;
                } : undefined}
                     alt={alt} style={imageStyle} title={title} onClick={onClick} ref={imageRef} onLoad={onLoad}/>
            </picture>
            {video}
        </>
    );
}

export default Image;
